<template>
  <div>
    <div :id="type"
         class="circularChart">
      <!-- {{type}} -->

    </div>
    <slot :money="money"></slot>
    <slot :strip="strip"></slot>
    <slot :totalDegree="totalDegree"></slot>
  </div>
</template>
<script>
//  
export default {
  props: {
    //	tableData: {
    //  type: Array,
    //    default: function() {
    //      return {}
    //    }
    //  },
    arrearageMoney: {
      // type: Array,
      default: function () {
        return {}
      }
    },
    arrearageStrip: {
      //  type: Array,
      default: function () {
        return {}
      }
    },
    temporaryParking: {
      //  type: Array,
      default: function () {
        return {}
      }
    },
    type: {
      default: 'c1'
    }
  },
  data () {
    return {
      money: '总金额:0.00元',
      strip: '出场订单总数:0(个)',
      totalDegree: '总停车次数:0次',
      formatterTitle1: "{b}<br/> 金额: {c}(元) <br/>占比: {d}%",
      formatterTitle2: "{b}<br/> 订单数: {c}(个) <br/>占比: {d}%",
      formatterTitle3: "{a} {b}<br/> 次数:　{c}次 <br/>占比:　{d}%",
      cityNameList: [0],
      shouldPayList: [0],
      actualPayList: [0],
      data1: [
        { value: 0, name: '欠费' },
        { value: 0, name: '实收' }
      ],
      data2: [
        { value: 0, name: '欠费' },
        { value: 0, name: '实收' }
      ],
      data3: [
        { value: 0, name: '临时停车' },
        { value: 0, name: '长时停车' }
      ]
    }
  },
  watch: {
    //  tableData: {
    //    handler(value) {
    //      this.totalNum = 0;
    //      this.dataList = [];
    //      this.nameList = [];
    //      for (var i = 0; i < value.length; i++) {
    //        this.totalNum += value[i].value * 1
    //        this.nameList.push(value[i].name)
    //        this.dataList.push({name: value[i].name, value: Number((value[i].value) / 100).toFixed(2)})
    //      }
    //      this.totalNum = (this.totalNum / 100).toFixed(2)
    //      this.drawGraph();
    //    },
    //    deep: true
    //  },
    arrearageMoney: {
      handler (value) {
        this.data1 = [];
        this.money = 0;
        for (var i = 0; i < value.length; i++) {
          this.money += Number((value[i].arrearageAmount) * 1) + Number((value[i].actualPay) * 1)
          this.data1.push({ name: '欠费', value: Number((value[i].arrearageAmount) / 100).toFixed(2) }, {
            name: '实收',
            value: Number((value[i].actualPay) / 100).toFixed(2)
          })
        }
		  let total = (this.money / 100).toFixed(2);
		  total = total.split(".")[0].replace(/(?=(\B)(\d{3})+$)/g, ",") + "." + total.split(".")[1];  
        // this.money = '总金额:' + (this.money / 100).toFixed(2) + '(元)'
        this.money = '总金额:' + total + '(元)'
        this.drawGraph();
      },
      deep: true
    },
    arrearageStrip: {
      handler (value) {
        this.data2 = [];
        this.strip = 0;
        for (var i = 0; i < value.length; i++) {
          this.strip += Number((value[i].arrearageNum) * 1) + Number((value[i].actualPayNum) * 1)
          this.data2.push({ name: '欠费', value: value[i].arrearageNum }, { name: '实收', value: value[i].actualPayNum })
        }
        this.strip = '出场订单总数:' + this.strip + '(个)'
        this.drawGraph();
      },
      deep: true
    },
    temporaryParking: {
      handler (value) {
        this.data3 = [];
        this.totalDegree = 0;
        for (var i = 0; i < value.length; i++) {
          this.totalDegree = '总停车次数：' + (value[i].totalParkNum * 1) + '次'
          this.data3.push({ name: '临时停车', value: value[i].shortParkNum }, { name: '长时停车', value: value[i].longParkNum })
        }
        // this.money = (this.totalNum / 100).toFixed(2)
        this.drawGraph();
      },
      deep: true
    }
  },
  methods: {
    drawGraph () {
      let that = this;
      let circularChart
      if (document.getElementById(this.type)) {
        circularChart = this.$echarts.init(document.getElementById(this.type));
      }
      let option = {
        tooltip: {
          trigger: 'item',
          // formatter: that.type == "c1" ? that.formatterTitle1 : that.type == "c2" ? that.formatterTitle2 : that.formatterTitle3
			 formatter: (params) => {
			   console.log('paramsparamsparams', params);
				let tipText = '';
				if (that.type == "c1") {
					let price = params.value;
					if (price) {
						price = price.split(".")[0].replace(/(?=(\B)(\d{3})+$)/g, ",") + "." + price.split(".")[1]
					}
					tipText = `${params.name}<br/> 金额: ${price}(元) <br/>占比: ${params.percent}%`;
				} else if (that.type == "c2") {
					tipText = `${params.name}<br/> 订单数: ${params.value}(个) <br/>占比: ${params.percent}%`;					
				} 
				return tipText;
			 }
        },
        legend: {
          orient: 'vertical',
          x: 'left',
          data: that.type == "c3" ? ['临时停车', '长时停车'] : ['实收', '欠费']
        },
        color: ['#FFA924', '#5AC8A8'],
        series: [
          {
            name: '类型:',
            type: 'pie',
            radius: ['60%', '73%'],
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
                position: 'center'
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: '30',
                  fontWeight: 'bold'
                }
              }
            },
            labelLine: {
              normal: {
                show: false
              }
            },
            data: that.type == "c1" ? that.data1 : that.type == "c2" ? that.data2 : that.data3
          }
        ]
      };
      circularChart.setOption(option);
    }
  },
  mounted () {
    this.drawGraph();
  },
  created () {
    // hub.$off('getAmount')
    //  hub.$on('getAmount', (data) => {
    //    if (data) {
    //      this.cityNameList = [];
    //      this.actualPayList = [];
    //      this.shouldPayList = [];
    //      for (var i = 0; i < data.length; i++) {
    //        this.cityNameList.push(data[i].cityName);
    //        this.actualPayList.push({
    //          value: data[i].actualPay / 100,
    //          name: data[i].cityName
    //        })
    //        this.shouldPayList.push({
    //          value: data[i].shouldPay / 100,
    //          name: data[i].cityName
    //        })
    //      }
    //      this.drawGraph();
    //    }
    //  })
  }
}
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.circularChart
  width 100%
  height 300px
  overflow hidden
</style>
