var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "graphTitle",
        { attrs: { title: "欠费金额趋势" } },
        [
          _c(
            "el-tooltip",
            {
              attrs: {
                placement: "top",
                content: "展示近12个月不同计费类型区域欠费金额对比情况",
              },
            },
            [_c("i", { staticClass: "el-icon-question my-icon" })]
          ),
        ],
        1
      ),
      _c("div", {
        staticClass: "circularChart",
        attrs: { id: "circularChart" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }