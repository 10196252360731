<!--
 * @Author: wch
 * @Date: 2021-12-17 16:52:47
 * @LastEditTime: 2022-09-05 14:53:52
 * @LastEditors: faf
 * @Description: 
 * @FilePath: \aiparkcity-acb\src\views\statisticalAnalysis\citywideDataAnalysis\arrearageSummation.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div>
    <div class="tabs1" >
      <div class="tab_item" :class="index == current ? 'tab_item_active' : ''" @click="changeCurrent(index)" v-for="(item, index) in tabs" :key="index">
        {{item}}
      </div>
    </div>
      <arrearageSummationTotal v-if="current == 0"></arrearageSummationTotal>
      <arrearageParkingList v-if="current == 1"></arrearageParkingList>
      <arrearagePlateList v-if="current == 2"></arrearagePlateList>
  </div>
</template>

<script>
import arrearageSummationTotal from './arrearageSummationTotal'
import arrearageParkingList from './arrearageSummation/arrearageParkingList'
import arrearagePlateList from './arrearageSummation/arrearagePlateList'

export default {
  components: {
    arrearageSummationTotal,
    arrearageParkingList,
    arrearagePlateList
  },
  data() {
    return {
      tabs: ['总体欠费分析', '车场欠费查询', '车辆欠费查询'],
      current: 0
    }
  },
  methods: {
      changeCurrent(index) {
        if (this.current == index) return;
        this.current = index
      }
    }
}
</script>

<style>
 .tabs1 {
   width: 100%;
   margin: 16px 0;
   display: flex;
   border-bottom: 1px solid #E3E4E6;
   background: #fff;
 }
 .tab_item {
    width: 104px; 
    height: 32px;
    line-height: 32px;
    text-align: center;
    /* margin-right: 10px; */
    font-weight: bold;
    color: #303030;
    cursor: pointer;
 }
 .tab_item_active {
   background-color: #fff;
   color: #527BFF;
   border-bottom: 2px solid #527BFF;
  margin-bottom: -2px;
 }
</style>
